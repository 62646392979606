import { ChangeDetectionStrategy, Component, signal, ViewEncapsulation } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslatePipe } from '@ngx-translate/core';

import { TooltipModule } from 'primeng/tooltip';
import { BadgeModule } from 'primeng/badge';

import { CompanyRouteEnum } from 'src/app/pages/company/company.routes.model';
import { AppRouteEnum } from 'src/app/app.routes.model';
import { UserRole } from '@dps/shared/models';
import { AuthStore, RootState } from '@dps/core/store';
import { AuthApiService } from '@dps/core/api/auth';
import { AuthRoutePath } from '../../../pages/auth';
import { FeatureFlagKey, FeatureFlagService } from '@dps/core/feature-flag';
import { Store } from '@ngxs/store';

@Component({
  selector: 'dps-main-menu',
  standalone: true,
  imports: [CommonModule, TooltipModule, TranslatePipe, RouterLink, RouterLinkActive, BadgeModule],
  templateUrl: './main-menu.component.html',
  styleUrl: './main-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class:
      'flex flex-column relative border-right-1 border-round-right-2xl surface-50 border-200 transition-duration-300 p-3 z-2',
    '[class.expanded]': 'isMenuExpanded()',
  },
})
export class MainMenuComponent {
  readonly isMenuExpanded = signal(false);
  readonly linkClasses: string = ['border-round', 'hover:surface-200', 'p-3'].join(' ');
  readonly activeLinkClasses: string = ['bg-primary', 'hover:bg-primary', 'active'].join(' ');
  readonly companyInvitationsRoute = ['../', CompanyRouteEnum.INVITATIONS];
  readonly companyProfileRoute = ['../', CompanyRouteEnum.PROFILE];
  readonly companyUserAccountsRoute = ['../', CompanyRouteEnum.USER_ACCOUNTS];
  readonly companyGroupsRoute = ['../', CompanyRouteEnum.GROUPS];
  readonly companyPlanningRoute = ['../', CompanyRouteEnum.PLANNING];
  readonly companyActualsRoute = ['../', CompanyRouteEnum.ACTUALS];
  readonly companyTimeRegistrationRoute = ['../', CompanyRouteEnum.TIME_REGISTRATION];
  readonly searchRoute = ['/', AppRouteEnum.SEARCH];
  readonly loginRoute = ['/', AuthRoutePath.LOGIN];
  readonly isGroupUser = this.authStore.hasRoles([UserRole.GROUP_USER]);
  readonly hasCustomerUserRole = this.authStore.hasRoles([
    UserRole.COMPANY_USER,
    UserRole.GROUP_USER,
  ]);
  readonly isGroupsEnabled = this.store.selectSignal(RootState.isCompanyGroupsEnabled);
  readonly isTimeRegistrationEnabled = this.store.selectSignal(
    RootState.isCompanyTimeRegistrationEnabled
  );
  readonly isActualsEnabled = this.store.selectSignal(RootState.isCompanyActualsEnabled);
  readonly companyContractConfirmationsCount = this.store.selectSignal(
    RootState.getCompanyActualsCount
  );
  readonly isActualsEpicEnabled$ = this.featureFlagService.getFlagValue$<boolean>(
    FeatureFlagKey.ACTUALS_EPIC
  );

  constructor(
    private store: Store,
    private authStore: AuthStore,
    private featureFlagService: FeatureFlagService,
    protected authApiService: AuthApiService
  ) {}

  toggleMenuExpansion(): void {
    this.isMenuExpanded.update(isExpanded => !isExpanded);
  }
}
