import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { GenericConfirmDialog } from '@dps/shared/models/confirm-dialog.model';

@Component({
  selector: 'dps-generic-confirm-dialog',
  imports: [TranslatePipe],
  templateUrl: './generic-confirm-dialog.component.html',
  styleUrl: './generic-confirm-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'flex flex-column align-items-center gap-5 pt-3',
  },
})
export class GenericConfirmDialogComponent {
  constructor(
    public dialogRef: DynamicDialogRef,
    private dialogService: DialogService
  ) {}

  readonly dialogData: GenericConfirmDialog | null = this.dialogService.getInstance(this.dialogRef)
    .data;
}
