<label class="mb-1" for="address-autocomplete">{{ label }}</label>

<div class="flex flex-column address-input-wrapper">
  <p-autoComplete
    [readonly]="readonly"
    #autocompleteInput
    [suggestions]="(searchSuggestions$ | async) || []"
    inputId="addressAutocomplete"
    styleClass="w-full"
    inputStyleClass="w-full"
    optionLabel="description"
    appendTo="body"
    [formControl]="autocompleteControl"
    [forceSelection]="true"
    [showClear]="true"
    [minLength]="minCharsToSearch"
    [overlayOptions]="suggestionOverlayOptions"
    (completeMethod)="loadSuggestions($event)"
    class="flex-grow-1"
  ></p-autoComplete>

  @if (control.value) {
    <input
      [readonly]="readonly"
      type="text"
      pInputText
      id="busInput"
      [placeholder]="'GENERAL.ADDRESS_BUS_LABEL' | translate"
      [formControl]="busControl"
      class="border-noround-top overflow-hidden"
    />
  }
</div>

<dps-field-validation-errors [control]="control">
  @if (control.hasError(addressErrorsEnum.STREET_NUMBER_INVALID)) {
    <small class="text-red-500">
      {{ 'VALIDATION_ERRORS.ADDRESS_STREET_NUMBER_INVALID' | translate }}
    </small>
  }
</dps-field-validation-errors>
