<span
  class="toggle-button dps-icon dps-icon-chevron absolute border-1 border-circle border-400 cursor-pointer surface-50 hover:surface-200 transition-transform transition-duration-300 z-3"
  [pTooltip]="
    (isMenuExpanded() ? 'COMPANY_SIDENAV.COLLAPSE' : 'COMPANY_SIDENAV.EXPAND') | translate
  "
  tooltipPosition="right"
  [ngClass]="{ 'rotate-180': isMenuExpanded() }"
  (click)="toggleMenuExpansion()"
></span>

@if (isMenuExpanded()) {
  <img
    src="assets/images/logo-with-title.svg"
    class="w-10rem align-self-center mt-3 mb-8 fadein transition-delay-500"
    alt="logo-with-title"
  />
} @else {
  <img
    src="assets/images/logo-icon.svg"
    class="w-2rem align-self-center mt-3 mb-8"
    alt="logo-icon"
  />
}

<div class="flex flex-column gap-2 flex-grow-1">
  <a
    [pTooltip]="'COMPANY_SIDENAV.COMPANY_PROFILE' | translate"
    [tooltipDisabled]="isMenuExpanded()"
    [routerLink]="companyProfileRoute"
    [routerLinkActive]="activeLinkClasses"
    [class]="linkClasses"
  >
    <span class="dps-icon dps-icon-building"></span>
    <span class="link-text">{{ 'COMPANY_SIDENAV.COMPANY_PROFILE' | translate }}</span>
  </a>

  <a
    [pTooltip]="'COMPANY_SIDENAV.USER_ACCOUNTS' | translate"
    [tooltipDisabled]="isMenuExpanded()"
    [routerLink]="companyUserAccountsRoute"
    [routerLinkActive]="activeLinkClasses"
    [class]="linkClasses"
  >
    <span class="dps-icon dps-icon-key"></span>
    <span class="link-text">{{ 'COMPANY_SIDENAV.USER_ACCOUNTS' | translate }}</span>
  </a>

  @if (!isGroupUser && isGroupsEnabled()) {
    <a
      [pTooltip]="'COMPANY_SIDENAV.GROUPS' | translate"
      [tooltipDisabled]="isMenuExpanded()"
      [routerLink]="companyGroupsRoute"
      [routerLinkActive]="activeLinkClasses"
      [class]="linkClasses"
    >
      <span class="dps-icon dps-icon-groups"></span>
      <span class="link-text">{{ 'COMPANY_SIDENAV.GROUPS' | translate }}</span>
    </a>
  }

  <a
    [pTooltip]="'COMPANY_SIDENAV.NEW_EMPLOYEES' | translate"
    [tooltipDisabled]="isMenuExpanded()"
    [routerLink]="companyInvitationsRoute"
    [routerLinkActive]="activeLinkClasses"
    [class]="linkClasses"
  >
    <span class="dps-icon dps-icon-person_add"></span>
    <span class="link-text">{{ 'COMPANY_SIDENAV.NEW_EMPLOYEES' | translate }}</span>
  </a>

  <a
    [pTooltip]="'COMPANY_SIDENAV.PLANNING' | translate"
    [tooltipDisabled]="isMenuExpanded()"
    [routerLink]="companyPlanningRoute"
    [routerLinkActive]="activeLinkClasses"
    [class]="linkClasses"
  >
    <span class="dps-icon dps-icon-event-note"></span>
    <span class="link-text">
      {{ 'COMPANY_SIDENAV.PLANNING' | translate }}
    </span>
  </a>

  @if ((isActualsEpicEnabled$ | async) && isActualsEnabled()) {
    @let companyContractConfirmationsCountValue = companyContractConfirmationsCount();

    <a
      [pTooltip]="'COMPANY_SIDENAV.ACTUALS' | translate"
      [tooltipDisabled]="isMenuExpanded()"
      [routerLink]="companyActualsRoute"
      [routerLinkActive]="activeLinkClasses"
      queryParamsHandling="merge"
      [class]="linkClasses"
    >
      <span
        class="dps-icon dps-icon-euro"
        pBadge
        [value]="companyContractConfirmationsCountValue"
        [badgeDisabled]="isMenuExpanded() || !companyContractConfirmationsCountValue"
        badgeStyleClass="p-0"
        [badgeStyle]="{ right: '-4px', top: '-4px' }"
      ></span>
      <span class="link-text flex-1">
        {{ 'COMPANY_SIDENAV.ACTUALS' | translate }}
      </span>

      @if (
        isMenuExpanded() && companyContractConfirmationsCountValue;
        as companyContractConfirmationsCount
      ) {
        <p-badge [value]="companyContractConfirmationsCount"></p-badge>
      }
    </a>
  }

  @if (isTimeRegistrationEnabled()) {
    <a
      [pTooltip]="'COMPANY_SIDENAV.TIME_REGISTRATION' | translate"
      [tooltipDisabled]="isMenuExpanded()"
      [routerLink]="companyTimeRegistrationRoute"
      [routerLinkActive]="activeLinkClasses"
      [class]="linkClasses"
    >
      <span class="dps-icon dps-icon-timer"></span>
      <span class="link-text">
        {{ 'COMPANY_SIDENAV.TIME_REGISTRATION' | translate }}
      </span>
    </a>
  }

  @if (!hasCustomerUserRole) {
    <a
      [pTooltip]="'COMPANY_SIDENAV.SEARCH_COMPANY' | translate"
      [tooltipDisabled]="isMenuExpanded()"
      [routerLink]="searchRoute"
      [routerLinkActive]="activeLinkClasses"
      [class]="linkClasses"
    >
      <span class="dps-icon dps-icon-search"></span>
      <span class="link-text">{{ 'COMPANY_SIDENAV.SEARCH_COMPANY' | translate }}</span>
    </a>
  }
</div>

<a
  [pTooltip]="'COMPANY_SIDENAV.LOGOUT' | translate"
  [tooltipDisabled]="isMenuExpanded()"
  [routerLinkActive]="activeLinkClasses"
  [routerLink]="loginRoute"
  (click)="authApiService.logout()"
  [class]="linkClasses"
>
  <span class="dps-icon dps-icon-logout rotate-180"></span>
  <span class="link-text">{{ 'COMPANY_SIDENAV.LOGOUT' | translate }}</span>
</a>
