<div class="flex align-items-center">
  @if (subtitle(); as subtitle) {
    <h3 [innerHTML]="subtitle" class="text-primary m-0 font-normal"></h3>
    <p-divider layout="vertical" class="hidden md:inline-block"></p-divider>
  }
  <h3 class="m-0 font-normal" [innerHTML]="title()"></h3>
</div>

<div class="flex-grow-1">
  <ng-content></ng-content>
</div>

<dps-app-locale-selector class="align-self-center"></dps-app-locale-selector>
