import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  signal,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import {
  combineLatest,
  filter,
  finalize,
  firstValueFrom,
  map,
  Observable,
  shareReplay,
  startWith,
  Subject,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { DateTime, Duration, Interval } from 'luxon';

import { Select } from 'primeng/select';
import { ButtonModule } from 'primeng/button';
import { DatePicker } from 'primeng/datepicker';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TooltipModule } from 'primeng/tooltip';
import { SkeletonModule } from 'primeng/skeleton';
import { TabsModule } from 'primeng/tabs';
import { InputNumberModule } from 'primeng/inputnumber';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { InputTextModule } from 'primeng/inputtext';
import { ToggleSwitch } from 'primeng/toggleswitch';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import { SelectButtonModule } from 'primeng/selectbutton';
import { DividerModule } from 'primeng/divider';
import { TextareaModule } from 'primeng/textarea';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

import {
  AddressModel,
  CancelReasonCodeEnum,
  ConsultantModel,
  ContractDayScheduleModel,
  ContractModel,
  ContractStatusEnum,
  ContractTimetableModel,
  UserRole,
  DICTIONARY_ITEM_OPTION_LABEL,
  DICTIONARY_ITEM_OPTION_VALUE,
  DictionaryItem,
  EmployeeWageModel,
  ShiftTemplateModel,
  CompanyDetailModel,
  ContractConfirmationStatus,
} from '@dps/shared/models';
import {
  ConsultantApiService,
  ContractApiService,
  ContractConfirmationApiService,
  DictionaryApiService,
  EmployeeWageApiService,
} from '@dps/core/api';
import {
  addressValidator,
  contractDayScheduleValidator,
  EXTRA_STATUTE_MULTI_DAY_CONTRACT_ERROR_NAME,
  extraStatuteMultiDayContractValidator,
  LATE_CONTRACT_ERROR_NAME,
  lateContractValidator,
  MAX_CONTRACT_DURATION_ERROR_NAME,
  maxContractDurationValidator,
} from '@dps/shared/validators';
import { ContractDialogDataModel } from './contract-dialog-data.model';
import {
  COMPANY_HOURS_PER_WEEK_MAX,
  DEFAULT_CONTRACT_DURATION,
  EMPLOYEE_GROSS_HOUR_WAGE_RANGE,
  MEAL_VOUCHERS_EMPLOYEE_MIN,
  MEAL_VOUCHERS_TOTAL_RANGE,
  MIN_CONTRACT_DURATION,
  MIN_CONTRACT_DURATION_PER_PC_CODE,
  MIN_SPAN_TO_CANCEL_TODAY_CONTRACT,
  MIN_SPAN_TO_START_TODAY_CONTRACT,
} from '@dps/shared/constants';
import {
  getContractDayScheduleDatetimes,
  mapContractToSchedulerEvent,
} from '@dps/shared/functions';
import { AuthStore, RootState } from '@dps/core/store';
import { ToggleCardComponent } from '../toggle-card/toggle-card.component';
import { AddressAutocompleteFieldComponent } from '../address-autocomplete-field/address-autocomplete-field.component';
import { FieldValidationErrorsComponent } from '../field-validation-errors/field-validation-errors.component';
import { TimeFieldComponent } from '../time-field/time-field.component';
import { Store } from '@ngxs/store';
import { ConfirmationService } from 'primeng/api';

type DatesRange = [start: Date | null, end: Date | null];

export type ScheduleDayForm = {
  shiftTemplateName: FormControl<string | null>;
  createShiftTemplate: FormControl<boolean>;
  date: FormControl<string>;
  fromTime: FormControl<string | null>;
  toTime: FormControl<string | null>;
  pauseFromTime: FormControl<string | null>;
  pauseToTime: FormControl<string | null>;
};

export type ContractDialogResponseModel = {
  usedMode: 'create' | 'update' | 'cancel';
};

@UntilDestroy()
@Component({
  selector: 'dps-contract-dialog',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    Select,
    ButtonModule,
    DatePicker,
    TranslatePipe,
    TooltipModule,
    SkeletonModule,
    TabsModule,
    InputNumberModule,
    ToggleSwitch,
    InputTextModule,
    ProgressSpinnerModule,
    AutoCompleteModule,
    SelectButtonModule,
    CheckboxModule,
    DividerModule,
    TextareaModule,
    RadioButtonModule,
    ToggleCardComponent,
    AddressAutocompleteFieldComponent,
    FieldValidationErrorsComponent,
    TimeFieldComponent,
    ConfirmDialogModule,
  ],
  templateUrl: './contract-dialog.component.html',
  styleUrl: './contract-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'flex flex-column pt-3 gap-3 relative',
  },
  providers: [ConfirmationService],
})
export class ContractDialogComponent implements OnInit {
  constructor(
    public dialogRef: DynamicDialogRef,
    private fb: FormBuilder,
    private dialogService: DialogService,
    private dictionaryApiService: DictionaryApiService,
    private employeeWageApiService: EmployeeWageApiService,
    private contractApiService: ContractApiService,
    private authStore: AuthStore,
    private consultantApiService: ConsultantApiService,
    private store: Store,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService,
    private contractConfirmationApiService: ContractConfirmationApiService
  ) {}

  private readonly today = DateTime.now();
  readonly companyHoursPerWeekMax = COMPANY_HOURS_PER_WEEK_MAX;
  readonly data: ContractDialogDataModel = this.dialogService.getInstance(this.dialogRef).data;
  readonly isCreateMode: boolean = this.data.contractEventRecord.hasGeneratedId;
  readonly isEditMode: boolean = !this.isCreateMode;
  readonly form = this.buildContractForm();
  readonly datesRangeControl = this.fb.control<DatesRange | null>(null, Validators.required);
  readonly wageControl = this.fb.control<EmployeeWageModel | null>(null, Validators.required);
  readonly scheduleFormArray = this.form.controls.timetable.controls.schedule;
  readonly travelAllowanceForm = this.form.controls.travelAllowance;
  readonly mealVoucherForm = this.form.controls.mealVoucher;
  readonly invoicingForm = this.form.controls.invoicing;

  readonly isLoadingContractData = signal(false);
  readonly dictionaryItemOptionLabel = DICTIONARY_ITEM_OPTION_LABEL;
  readonly dictionaryItemOptionValue = DICTIONARY_ITEM_OPTION_VALUE;
  readonly company = this.store.selectSnapshot(RootState.getCompanyData) as CompanyDetailModel;
  readonly isLoadingWages = signal(false);
  readonly wages$: Observable<Array<EmployeeWageModel>> = this.employeeWageApiService
    .getEmployeeWages({
      employeeId: this.data.employee.id,
      companyId: this.company.id,
    })
    .pipe(
      tap(wages => {
        if (wages.length === 1) {
          this.wageControl.setValue(wages[0]);
          this.wageControl.disable();
        }
      }),
      tap(() => this.isLoadingWages.set(false))
    );
  readonly travelAllowances$ = this.dictionaryApiService.getDictionary('travelallowances');
  readonly defaultTaxRates$ = this.dictionaryApiService.getDictionary('defaulttaxrates');
  readonly reasons$ = this.dictionaryApiService.getDictionary('reasons');
  readonly compensationHours$ = this.dictionaryApiService.getDictionary('compensationhours');
  readonly consultants$ = this.consultantApiService.getConsultants();
  readonly cancelReasons$ = this.dictionaryApiService
    .getDictionary<CancelReasonCodeEnum>('cancelreasons')
    .pipe(
      map(reasons =>
        reasons.filter(reason =>
          [
            CancelReasonCodeEnum.TIME_SCHEDULE_SHOULD_BE_UPDATED,
            CancelReasonCodeEnum.EMPLOYEE_WILL_NOT_WORK,
          ].includes(reason.code)
        )
      )
    );

  readonly minShiftNameLength = 3;
  readonly shiftSearchQuery = new Subject<string>();
  readonly shiftsRefresher$ = new Subject<void>();
  readonly shifts$ = combineLatest([
    this.shiftSearchQuery.asObservable(),
    this.shiftsRefresher$.asObservable().pipe(startWith(undefined)),
  ]).pipe(
    switchMap(([query]) =>
      this.contractApiService.getShiftTemplates({
        nameLike: query,
        companyId: this.company.id,
      })
    ),
    shareReplay(1)
  );
  readonly useExistingShiftControl = this.fb.control<boolean>(true);
  readonly shiftTypeOptions = [
    { labelTranslationKey: 'CONTRACT.EXISTING_SHIFT', value: true },
    { labelTranslationKey: 'CONTRACT.NEW_SHIFT', value: false },
  ];
  readonly shiftAutocompleteControl = this.fb.control<ShiftTemplateModel | null>(
    null,
    Validators.required
  );
  readonly isScheduleCopied = signal(false);
  readonly lateContractErrorName = LATE_CONTRACT_ERROR_NAME;
  readonly extraStatuteMultiDayContractErrorName = EXTRA_STATUTE_MULTI_DAY_CONTRACT_ERROR_NAME;
  readonly minContractDurationErrorName = 'minContractDuration';
  readonly maxContractDurationErrorName = MAX_CONTRACT_DURATION_ERROR_NAME;
  selectedScheduleDayIndex = 0;
  originalContract!: ContractModel;

  readonly confirmedScheduleDaysIndexes = new Set<number>();
  readonly isProcessingContract = signal(false);
  private readonly isCompanyActualsEnabled = this.store.selectSnapshot(
    RootState.isCompanyActualsEnabled
  );
  readonly isPastContractWithConfirmedActual = signal(false);

  isCancelMode = false;
  readonly isTabsFormVisible: boolean =
    this.isEditMode &&
    this.authStore.hasRoles([
      UserRole.FULL_ADMIN,
      UserRole.SUPER_ADMIN,
      UserRole.SALES_ADMIN,
      UserRole.CREDIT_CONTROLLER,
      UserRole.PREVENTION_ADVISOR,
      UserRole.RECRUITER,
    ]);
  readonly canCreateLateContract: boolean = this.authStore.hasRoles([
    UserRole.FULL_ADMIN,
    UserRole.SUPER_ADMIN,
  ]);
  readonly hasLimitedAccess: boolean = this.authStore.hasRoles([
    UserRole.DPS_DIRECTOR,
    UserRole.DPS_SALES,
    UserRole.CREDIT_CONTROLLER,
    UserRole.PREVENTION_ADVISOR,
    UserRole.RECRUITER,
    UserRole.COMPANY_USER,
    UserRole.GROUP_USER,
  ]);
  minDate: Date | null = this.canCreateLateContract ? null : new Date(new Date().toDateString());
  maxDate: Date | null = null;

  get canCancelContract(): boolean {
    if (this.isCreateMode || this.isCancelMode) return false;

    if (this.authStore.hasRoles([UserRole.FULL_ADMIN, UserRole.SUPER_ADMIN, UserRole.SALES_ADMIN]))
      return true;

    const startDate = this.data.contractEventRecord.getData('dateFrom');
    const startTime = this.data.contractEventRecord.getData('timetable').schedule[0].fromTime;
    const contractStartDatetime = DateTime.fromSQL(`${startDate} ${startTime}`);

    return (
      contractStartDatetime.diff(this.today).as('minutes') >=
      MIN_SPAN_TO_CANCEL_TODAY_CONTRACT.as('minutes')
    );
  }

  get isSingleDayContract(): boolean {
    return this.scheduleFormArray.length === 1;
  }

  get isFirstScheduleDaySelected(): boolean {
    return this.selectedScheduleDayIndex === 0;
  }

  get isLastScheduleDaySelected(): boolean {
    return this.selectedScheduleDayIndex + 1 === this.scheduleFormArray.length;
  }

  get selectedScheduleDayForm() {
    return this.scheduleFormArray.at(this.selectedScheduleDayIndex);
  }

  get selectedScheduleFormattedDate(): string | null {
    if (!this.scheduleFormArray.length) return null;

    const { date } = this.selectedScheduleDayForm.getRawValue();
    return DateTime.fromISO(date).toLocaleString(DateTime.DATE_HUGE);
  }

  get isSelectedScheduleDayHasMinWorkDuration(): boolean {
    const selectedPC = this.form.getRawValue().paritairComite;
    if (!selectedPC) return true;

    const minPureWorkDurationByPC: Duration =
      MIN_CONTRACT_DURATION_PER_PC_CODE[selectedPC.code] || MIN_CONTRACT_DURATION;
    const { startDatetime, endDatetime, pauseStartDatetime, pauseEndDatetime } =
      getContractDayScheduleDatetimes(
        this.selectedScheduleDayForm.getRawValue() as ContractDayScheduleModel
      );
    const workDuration = Interval.fromDateTimes(startDatetime, endDatetime).toDuration();
    const pauseDuration =
      pauseStartDatetime.isValid && pauseEndDatetime.isValid
        ? Interval.fromDateTimes(pauseStartDatetime, pauseEndDatetime).toDuration()
        : null;
    const pureWorkDuration = pauseDuration?.isValid
      ? workDuration.minus(pauseDuration)
      : workDuration;
    const dayHasMinWorkDuration =
      pureWorkDuration.as('minutes') >= minPureWorkDurationByPC.as('minutes');

    if (!dayHasMinWorkDuration) {
      this.selectedScheduleDayForm.setErrors({
        [this.minContractDurationErrorName]: true,
      });
    }

    return dayHasMinWorkDuration;
  }

  get isAllContractSchedulesConfirmed(): boolean {
    return this.confirmedScheduleDaysIndexes.size === this.scheduleFormArray.length;
  }

  get canCopySchedule(): boolean {
    return (
      !this.isSingleDayContract &&
      this.isFirstScheduleDaySelected &&
      this.scheduleFormArray.at(0).valid
    );
  }

  get isDayScheduleSectionShown(): boolean {
    return (
      this.isEditMode ||
      (this.useExistingShiftControl.value && this.shiftAutocompleteControl.valid) ||
      !this.useExistingShiftControl.value
    );
  }

  ngOnInit(): void {
    this.initFormListeners();
    this.wageControl.markAsDirty();
    this.shiftAutocompleteControl.markAsDirty();

    // Disable all form controls except ones that are required for contract creation/edition
    if (this.isCreateMode || !this.isTabsFormVisible) {
      this.form.disable();
      this.form.controls.dateFrom.enable();
      this.form.controls.dateTo.enable();
      this.form.controls.employmentAddress.enable();
      this.scheduleFormArray.enable();
    }

    if (this.isCreateMode) {
      this.isLoadingWages.set(true);

      this.contractApiService
        .getShiftTemplates({
          companyId: this.company.id,
        })
        .subscribe(shiftTemplate => {
          if (shiftTemplate.length) {
            this.shiftAutocompleteControl.patchValue({
              ...shiftTemplate[0],
              name: shiftTemplate[0].name,
            });
          } else {
            this.useExistingShiftControl.setValue(false);
          }
        });

      this.datesRangeControl.setValue([
        this.data.contractEventRecord.getData('startDate'),
        this.data.contractEventRecord.getData('endDate'),
      ]);
    }

    if (this.isEditMode) {
      this.isLoadingContractData.set(true);
      this.contractApiService
        .getContract(this.data.contractEventRecord.getData('id'))
        .subscribe(contract => {
          this.originalContract = structuredClone(contract);
          const startDate: Date = DateTime.fromISO(contract.dateFrom).toJSDate();
          const endDate: Date = DateTime.fromISO(contract.dateTo).toJSDate();

          this.datesRangeControl.setValue([startDate, endDate]);
          if (contract.dateFrom === contract.dateTo) {
            this.datesRangeControl.disable({ emitEvent: false });
          }
          this.minDate = startDate;
          this.maxDate = endDate;
          this.form.patchValue(contract);
          if (
            !this.authStore.hasRoles([
              UserRole.FULL_ADMIN,
              UserRole.SUPER_ADMIN,
              UserRole.SALES_ADMIN,
            ])
          ) {
            this.disablePassedContractDatesSchedules();
          }
          if (this.authStore.hasRoles([UserRole.SALES_ADMIN])) {
            const contractLengths = this.scheduleFormArray.controls.length;
            const { date, toTime } = this.scheduleFormArray.at(contractLengths - 1).getRawValue();
            const scheduleDayEndDatetime = DateTime.fromSQL(`${date} ${toTime}`);
            if (
              scheduleDayEndDatetime.startOf('minute').plus({ days: 1 }) <
              this.today.startOf('minute')
            ) {
              this.datesRangeControl.disable();
            }
          }
          this.isLoadingContractData.set(false);
        });

      const { schedule } = this.data.contractEventRecord.getData('timetable');
      const lastScheduleDay = schedule[schedule.length - 1];
      const contractEndDatetime = DateTime.fromSQL(
        `${lastScheduleDay.date} ${lastScheduleDay.toTime}`
      );
      if (this.isCompanyActualsEnabled && contractEndDatetime < this.today) {
        this.contractConfirmationApiService
          .getContractsConfirmations({
            companyId: this.company.id,
            contractId: this.data.contractEventRecord.getData('id'),
            statuses: [ContractConfirmationStatus.CONFIRMED, ContractConfirmationStatus.ABSENT],
          })
          .subscribe(resp => this.isPastContractWithConfirmedActual.set(!!resp.content.length));
      }
    }

    if (this.isCancelMode || this.hasLimitedAccess) {
      this.form.controls.employmentAddress.disable();
    }
  }

  createContract(): void {
    if (this.form.invalid || !this.isAllContractSchedulesConfirmed) return;

    this.isProcessingContract.set(true);
    const selectedWage = this.wageControl.getRawValue() as EmployeeWageModel;
    const payload: ContractModel = {
      ...(this.form.getRawValue() as unknown as ContractModel),
      id: '',
      allocationId: selectedWage.allocationId,
      revenueOfficeCode: selectedWage.revenueOfficeCode,
      status: ContractStatusEnum.ACTIVE,
      employeeId: this.data.employee.id,
      companyId: this.company.id,
    };

    this.contractApiService
      .createContract(payload)
      .pipe(finalize(() => this.isProcessingContract.set(false)))
      .subscribe(({ id }) => {
        // If contract dates overlaps months, the contract will be split on BE but only 1 will be returned. Use payload to get correct dates
        this.data.contractEventRecord.set(
          mapContractToSchedulerEvent({
            ...payload,
            id,
          })
        );
        this.dialogRef.close({
          usedMode: 'create',
        } satisfies ContractDialogResponseModel);
      });
  }

  updateContract(): void {
    if (this.form.invalid) return;
    this.isProcessingContract.set(true);
    const payload = {
      ...this.originalContract,
      ...(this.form.value as Partial<ContractModel>),
      timetable: this.form.controls.timetable.getRawValue() as ContractTimetableModel,
    } satisfies ContractModel;

    this.contractApiService
      .updateContract(payload)
      .pipe(finalize(() => this.isProcessingContract.set(false)))
      .subscribe(updatedContract => {
        this.data.contractEventRecord.set(mapContractToSchedulerEvent(updatedContract));
        this.dialogRef.close({
          usedMode: 'update',
        } satisfies ContractDialogResponseModel);
      });
  }

  async cancelContract() {
    if (this.form.invalid) return;

    const { schedule } = this.originalContract.timetable;
    const contractEndDatetime = DateTime.fromSQL(
      `${this.originalContract.dateTo} ${schedule[schedule.length - 1].toTime}`
    );

    if (this.isCompanyActualsEnabled && DateTime.now() > contractEndDatetime) {
      const isConfirmed$ = new Subject<boolean>();

      this.confirmationService.confirm({
        header: this.translateService.instant('CONTRACT.CANCEL_CONTRACT_CONFIRMATION_DIALOG.TITLE'),
        message: this.translateService.instant(
          'CONTRACT.CANCEL_CONTRACT_CONFIRMATION_DIALOG.MESSAGE'
        ),
        icon: 'dps-icon dps-icon-warning text-orange-500',
        rejectButtonProps: {
          label: this.translateService.instant(
            'CONTRACT.CANCEL_CONTRACT_CONFIRMATION_DIALOG.CANCEL_BUTTON'
          ),
          severity: 'secondary',
          outlined: true,
        },
        acceptButtonProps: {
          label: this.translateService.instant(
            'CONTRACT.CANCEL_CONTRACT_CONFIRMATION_DIALOG.CONFIRM_BUTTON'
          ),
          severity: 'danger',
        },
        closable: false,
        accept: () => isConfirmed$.next(true),
        reject: () => isConfirmed$.next(false),
      });

      const isConfirmed = await firstValueFrom(isConfirmed$);
      if (!isConfirmed) return;
    }

    this.isProcessingContract.set(true);

    this.contractApiService
      .updateContract({
        ...this.originalContract,
        ...(this.form.value as Pick<ContractModel, 'cancelReason' | 'cancelExtraInfo'>),
        status: ContractStatusEnum.CANCELLED,
      })
      .pipe(finalize(() => this.isProcessingContract.set(false)))
      .subscribe(() => {
        this.dialogRef.close({
          usedMode: 'cancel',
        } satisfies ContractDialogResponseModel);
      });
  }

  selectScheduleDayIndex(index: number): void {
    this.selectedScheduleDayIndex = index;
    this.confirmedScheduleDaysIndexes.add(index);
    // When switching between schedule days, display type of shift used and shift name in autocomplete if any
    const selectedScheduleDayFormValue = this.selectedScheduleDayForm?.getRawValue();
    if (!selectedScheduleDayFormValue) return;

    this.useExistingShiftControl.setValue(!!selectedScheduleDayFormValue?.shiftTemplateName, {
      emitEvent: false,
    });
    if (selectedScheduleDayFormValue.shiftTemplateName) {
      this.shiftAutocompleteControl.patchValue(
        {
          ...(selectedScheduleDayFormValue as any),
          name: selectedScheduleDayFormValue.shiftTemplateName,
        },
        { emitEvent: false }
      );
    } else {
      this.shiftAutocompleteControl.reset();
    }
  }

  enableCancelMode(): void {
    this.isCancelMode = true;

    this.form.disable({ emitEvent: false });
    this.datesRangeControl.disable({ emitEvent: false });
    this.form.controls.cancelReason.enable();
    this.form.controls.cancelExtraInfo.enable();
  }

  copySchedule(): void {
    if (!this.canCopySchedule) return;

    this.isScheduleCopied.set(true);
    const { fromTime, toTime, pauseFromTime, pauseToTime } = this.scheduleFormArray
      .at(0)
      .getRawValue();
    this.scheduleFormArray.controls.forEach((scheduleDayForm, index) => {
      if (index === 0) return;
      this.confirmedScheduleDaysIndexes.add(index);
      scheduleDayForm.patchValue({ fromTime, toTime, pauseFromTime, pauseToTime });
    });

    setTimeout(() => this.isScheduleCopied.set(false), 1500);
  }

  removeShift(event: Event, shift: ShiftTemplateModel): void {
    event.stopPropagation();

    this.contractApiService.removeShiftTemplate(shift.id).subscribe(() => {
      this.shiftsRefresher$.next();

      if (shift.id === this.shiftAutocompleteControl.value?.id) {
        this.shiftAutocompleteControl.reset();
      }
    });
  }

  private disablePassedContractDatesSchedules(): void {
    for (const scheduleDayForm of this.scheduleFormArray.controls) {
      const { date, toTime } = scheduleDayForm.getRawValue();
      const scheduleDayEndDatetime = DateTime.fromSQL(`${date} ${toTime}`);
      if (scheduleDayEndDatetime.startOf('minute') > this.today.startOf('minute')) {
        break;
      }
      scheduleDayForm.disable();
    }
  }

  private initFormListeners(): void {
    this.datesRangeControl.valueChanges
      .pipe(
        startWith(this.datesRangeControl.value),
        filter(Boolean),
        filter(range => range.every(Boolean)),
        untilDestroyed(this)
      )
      .subscribe(([startDate, endDate]) => {
        const startDatetime = DateTime.fromJSDate(startDate as Date).startOf('day');
        const endDatetime = DateTime.fromJSDate(endDate as Date).endOf('day');

        this.generateSchedule(startDatetime, endDatetime);
        this.form.patchValue({
          dateFrom: startDatetime.toISODate(),
          dateTo: endDatetime.toISODate(),
          // Keep schedule data when making contract days shorter (will work only if we allow to decrease contract days from the end, otherwise will break)
          timetable: this.data.contractEventRecord.getData('timetable'),
        });

        // Reflect changes of dates in scheduler when creating contract
        if (this.isCreateMode) {
          this.data.contractEventRecord.set({
            startDate: startDatetime.toJSDate(),
            endDate: endDatetime.toJSDate(),
          });
        }
      });

    this.wageControl.valueChanges
      .pipe(filter(Boolean), untilDestroyed(this))
      .subscribe(selectedWage => this.form.patchValue(selectedWage));

    const { travelAllowance, forfait, distanceKm } = this.travelAllowanceForm.controls;
    const { shareTotal, shareCompany, shareEmployee, minimumHours } = this.mealVoucherForm.controls;

    this.travelAllowanceForm.controls.isEnabled.valueChanges
      .pipe(startWith(this.travelAllowanceForm.controls.isEnabled.value), untilDestroyed(this))
      .subscribe(isTravelAllowanceEnabled => {
        travelAllowance.reset({
          value: isTravelAllowanceEnabled ? travelAllowance.value : null,
          disabled: !isTravelAllowanceEnabled,
        });
        forfait.reset({
          value: isTravelAllowanceEnabled ? forfait.value : null,
          disabled: !isTravelAllowanceEnabled,
        });
        distanceKm.reset({
          value: isTravelAllowanceEnabled ? distanceKm.value : null,
          disabled: !isTravelAllowanceEnabled,
        });
      });

    this.mealVoucherForm.controls.isEnabled.valueChanges
      .pipe(startWith(this.mealVoucherForm.controls.isEnabled.value), untilDestroyed(this))
      .subscribe(isMealVoucherEnabled => {
        shareTotal.reset({
          value: isMealVoucherEnabled ? shareTotal.value : null,
          disabled: !isMealVoucherEnabled,
        });
        shareCompany.reset({
          value: isMealVoucherEnabled ? shareCompany.value : null,
          disabled: !isMealVoucherEnabled,
        });
        shareEmployee.reset({
          value: isMealVoucherEnabled ? shareEmployee.value : null,
          disabled: !isMealVoucherEnabled,
        });
        minimumHours.reset({
          value: isMealVoucherEnabled ? minimumHours.value : null,
          disabled: !isMealVoucherEnabled,
        });
      });

    if (this.isCreateMode) {
      this.useExistingShiftControl.valueChanges
        .pipe(untilDestroyed(this))
        .subscribe(useExistingShift => {
          if (useExistingShift && !this.selectedScheduleDayForm.controls.shiftTemplateName.value) {
            this.selectedScheduleDayForm.reset();
          }

          if (!useExistingShift && this.selectedScheduleDayForm.invalid) {
            const selectedScheduleDayDatetime = DateTime.fromISO(
              this.selectedScheduleDayForm.getRawValue().date
            );
            const { startTime, endTime } = this.getDefaultContractHoursByDate(
              selectedScheduleDayDatetime
            );
            this.selectedScheduleDayForm.patchValue({ fromTime: startTime, toTime: endTime });
          }
        });

      this.shiftAutocompleteControl.valueChanges
        .pipe(filter(Boolean), untilDestroyed(this))
        .subscribe(selectedShift =>
          this.selectedScheduleDayForm.patchValue({
            ...selectedShift,
            shiftTemplateName: selectedShift.name,
          })
        );
    }
  }

  private generateSchedule(startDate: DateTime, endDate: DateTime): void {
    this.confirmedScheduleDaysIndexes.clear();
    this.selectScheduleDayIndex(0);
    this.scheduleFormArray.reset();
    this.scheduleFormArray.clear();

    const consecutiveDaysCount = Math.ceil(endDate.diff(startDate, 'days').days);

    Array.from({ length: consecutiveDaysCount }).forEach((_, index) => {
      const scheduleDayDate = startDate.plus({ days: index });
      const scheduleDayValidators: ValidatorFn[] = [
        contractDayScheduleValidator(),
        maxContractDurationValidator(),
      ];
      if (
        this.isCreateMode &&
        !this.canCreateLateContract &&
        scheduleDayDate.hasSame(this.today, 'day')
      ) {
        scheduleDayValidators.push(lateContractValidator());
      }
      const scheduleDayFormGroup = this.fb.group(
        {
          shiftTemplateName: this.fb.control<string | null>(null, [
            Validators.required,
            Validators.minLength(this.minShiftNameLength),
          ]),
          createShiftTemplate: this.fb.nonNullable.control<boolean>(false),
          date: this.fb.nonNullable.control<string>(scheduleDayDate.toISODate() as string),
          fromTime: this.fb.control<string | null>(null, Validators.required),
          toTime: this.fb.control<string | null>(null, Validators.required),
          pauseFromTime: this.fb.control<string | null>(null),
          pauseToTime: this.fb.control<string | null>(null),
        },
        {
          updateOn: 'blur',
          validators: scheduleDayValidators,
        }
      );

      const { createShiftTemplate, shiftTemplateName, fromTime } = scheduleDayFormGroup.controls;

      if (this.isEditMode && !this.canCreateLateContract) {
        fromTime.valueChanges
          .pipe(
            filter(() => fromTime.dirty),
            take(1)
          )
          .subscribe(() => {
            scheduleDayFormGroup.addValidators(lateContractValidator());
            scheduleDayFormGroup.updateValueAndValidity();
          });
      }

      createShiftTemplate.valueChanges
        .pipe(startWith(createShiftTemplate.value), untilDestroyed(this))
        .subscribe(createShiftTemplateValue => {
          if (createShiftTemplateValue) {
            shiftTemplateName.enable();
            shiftTemplateName.markAsDirty();
            return;
          }

          shiftTemplateName.disable();
          shiftTemplateName.reset();
        });

      this.scheduleFormArray.push(scheduleDayFormGroup);
    });
  }

  private getDefaultContractHoursByDate(date: DateTime): {
    startTime: string;
    endTime: string;
  } {
    const isDateToday = date.hasSame(this.today, 'day');
    const startDatetime = isDateToday
      ? this.today.plus(MIN_SPAN_TO_START_TODAY_CONTRACT)
      : date.set({ hour: 9, minute: 0 });
    const endDatetime = startDatetime.plus(
      isDateToday ? MIN_CONTRACT_DURATION : DEFAULT_CONTRACT_DURATION
    );

    return {
      startTime: startDatetime.toLocaleString(DateTime.TIME_24_SIMPLE),
      endTime: endDatetime.toLocaleString(DateTime.TIME_24_SIMPLE),
    };
  }

  private buildContractForm() {
    const [minWage, maxWage] = EMPLOYEE_GROSS_HOUR_WAGE_RANGE;
    const [mealVouchersTotalMin, mealVouchersTotalMax] = MEAL_VOUCHERS_TOTAL_RANGE;
    const formValidators: ValidatorFn[] = [];

    if (this.isCreateMode) {
      formValidators.push(extraStatuteMultiDayContractValidator());
    }

    return this.fb.group(
      {
        // General tab controls
        dateFrom: this.fb.control<string | null>(null),
        dateTo: this.fb.control<string | null>(null),
        position: this.fb.control<string | null>(null),
        statute: this.fb.control<DictionaryItem | null>(null),
        paritairComite: this.fb.control<DictionaryItem | null>(null),
        employmentAddress: this.fb.control<AddressModel | null>(null, [
          Validators.required,
          addressValidator(),
        ]),
        timetable: this.fb.group({
          schedule: this.fb.array<FormGroup<ScheduleDayForm>>([]),
        }),

        // Wage tab controls
        wageHour: this.fb.control<number | null>(null, [
          Validators.required,
          Validators.min(minWage),
          Validators.max(maxWage),
        ]),
        travelAllowance: this.fb.group({
          isEnabled: this.fb.nonNullable.control<boolean>(false),
          travelAllowance: this.fb.control<DictionaryItem | null>(null, Validators.required),
          distanceKm: this.fb.control<number | null>(null),
          forfait: this.fb.control<number | null>(null),
        }),
        mealVoucher: this.fb.group({
          isEnabled: this.fb.nonNullable.control<boolean>(false),
          shareTotal: this.fb.control<number | null>(null, [
            Validators.required,
            Validators.min(mealVouchersTotalMin),
            Validators.max(mealVouchersTotalMax),
          ]),
          shareCompany: this.fb.control<number | null>(null, Validators.required),
          shareEmployee: this.fb.control<number | null>(null, [
            Validators.required,
            Validators.min(MEAL_VOUCHERS_EMPLOYEE_MIN),
          ]),
          minimumHours: this.fb.control<number | null>(null),
        }),
        invoiceEcoWeekly: this.fb.nonNullable.control<boolean>(true),

        // Invoicing tab group
        invoicing: this.fb.group({
          coefficient: this.fb.control<number | null>(null, Validators.required),
          coefficientTravelAllowance: this.fb.control<number | null>(null, Validators.required),
          coefficientMealVouchers: this.fb.control<number | null>(null, Validators.required),
          coefficientEcoVouchers: this.fb.control<number | null>(null, Validators.required),
          coefficientBankHoliday: this.fb.control<number | null>(null, Validators.required),
          dimonaCost: this.fb.control<number | null>(null, Validators.required),
          defaultTaxRate: this.fb.control<DictionaryItem | null>(null, Validators.required),
        }),

        // Other tab controls
        reason: this.fb.control<DictionaryItem | null>(null, Validators.required),
        compensationHours: this.fb.control<DictionaryItem | null>(null, Validators.required),
        revenueConsultant: this.fb.control<ConsultantModel | null>(null, Validators.required),
        employeeHoursPerWeek: this.fb.control<number | null>(null, Validators.required),
        companyHoursPerWeek: this.fb.control<number | null>(null, [
          Validators.required,
          Validators.max(COMPANY_HOURS_PER_WEEK_MAX),
        ]),

        // Cancel mode controls
        cancelReason: this.fb.control<DictionaryItem | null>(
          { value: null, disabled: true },
          Validators.required
        ),
        cancelExtraInfo: this.fb.control<string | null>(null),
      },
      { validators: formValidators }
    );
  }
}
