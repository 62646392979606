<label class="mb-1" for="phoneNumber">
  {{ labelTranslationKey() | translate }}
</label>
<input type="tel" pInputText id="phoneNumber" [formControl]="control()" [readonly]="isReadonly()" />

<dps-field-validation-errors [control]="control()">
  @if (control().hasError(phoneNumberInvalidError)) {
    <small class="text-red-500">{{ 'VALIDATION_ERRORS.PHONE_NUMBER_INVALID' | translate }}</small>
  }
</dps-field-validation-errors>
