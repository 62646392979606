import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { DividerModule } from 'primeng/divider';

import { AppLocaleSelectorComponent } from '../app-locale-selector/app-locale-selector.component';

@Component({
  selector: 'dps-page-header',
  standalone: true,
  imports: [CommonModule, DividerModule, AppLocaleSelectorComponent],
  templateUrl: './page-header.component.html',
  styleUrl: './page-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'flex p-3 border-bottom-1 border-200 z-1',
  },
})
export class PageHeaderComponent {
  readonly title = input<string>();
  readonly subtitle = input<string | null>();
}
